define("discourse/plugins/discourse-calendar/discourse/controllers/discourse-post-event-builder", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax-error", "discourse/lib/text", "discourse/mixins/modal-functionality", "discourse/models/group", "I18n", "discourse/plugins/discourse-calendar/discourse/lib/raw-event-helper"], function (_exports, _controller, _object, _computed, _service, _ajaxError, _text, _modalFunctionality, _group, _I18n, _rawEventHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const DEFAULT_REMINDER = {
    type: "notification",
    value: 15,
    unit: "minutes",
    period: "before"
  };
  function replaceTimezone(val, newTimezone) {
    return moment.tz(val.format("YYYY-MM-DDTHH:mm"), newTimezone);
  }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    dialog: (0, _service.inject)(),
    reminders: null,
    isLoadingReminders: false,
    init() {
      this._super(...arguments);
      this.set("reminderTypes", [{
        value: "notification",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.types.notification")
      }, {
        value: "bumpTopic",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.types.bump_topic")
      }]);
      this.set("reminderUnits", [{
        value: "minutes",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.units.minutes")
      }, {
        value: "hours",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.units.hours")
      }, {
        value: "days",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.units.days")
      }, {
        value: "weeks",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.units.weeks")
      }]);
      this.set("reminderPeriods", [{
        value: "before",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.periods.before")
      }, {
        value: "after",
        name: _I18n.default.t("discourse_post_event.builder_modal.reminders.periods.after")
      }]);
      this.set("availableRecurrences", [{
        id: "every_day",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_day")
      }, {
        id: "every_month",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_month")
      }, {
        id: "every_weekday",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_weekday")
      }, {
        id: "every_week",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_week")
      }, {
        id: "every_two_weeks",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_two_weeks")
      }, {
        id: "every_four_weeks",
        name: _I18n.default.t("discourse_post_event.builder_modal.recurrence.every_four_weeks")
      }]);
    },
    modalTitle: (0, _object.computed)("model.eventModel.isNew", {
      get() {
        return this.model.eventModel.isNew ? "create_event_title" : "update_event_title";
      }
    }),
    allowedCustomFields: (0, _object.computed)("siteSettings.discourse_post_event_allowed_custom_fields", function () {
      return this.siteSettings.discourse_post_event_allowed_custom_fields.split("|").filter(Boolean);
    }),
    groupFinder(term) {
      return _group.default.findAll({
        term,
        ignore_automatic: true
      });
    },
    allowsInvitees: (0, _computed.equal)("model.eventModel.status", "private"),
    addReminderDisabled: (0, _computed.gte)("model.eventModel.reminders.length", 5),
    onChangeCustomField(field, event) {
      const value = event.target.value;
      (0, _object.set)(this.model.eventModel.custom_fields, field, value);
    },
    onChangeStatus(newStatus) {
      this.model.eventModel.set("raw_invitees", []);
      if (newStatus === "private") {
        this.setRawInvitees(null, this.model.eventModel.raw_invitees.filter(x => x !== "trust_level_0"));
      }
      this.set("model.eventModel.status", newStatus);
    },
    setRawInvitees(_, newInvitees) {
      this.set("model.eventModel.raw_invitees", newInvitees);
    },
    removeReminder(reminder) {
      this.model.eventModel.reminders.removeObject(reminder);
    },
    addReminder() {
      if (!this.model.eventModel.reminders) {
        this.model.eventModel.set("reminders", []);
      }
      this.model.eventModel.reminders.pushObject(Object.assign({}, DEFAULT_REMINDER));
    },
    startsAt: (0, _object.computed)("model.eventModel.starts_at", {
      get() {
        return moment(this.model.eventModel.starts_at).tz(this.model.eventModel.timezone || "UTC");
      }
    }),
    endsAt: (0, _object.computed)("model.eventModel.ends_at", {
      get() {
        return this.model.eventModel.ends_at && moment(this.model.eventModel.ends_at).tz(this.model.eventModel.timezone || "UTC");
      }
    }),
    standaloneEvent: (0, _computed.equal)("model.eventModel.status", "standalone"),
    publicEvent: (0, _computed.equal)("model.eventModel.status", "public"),
    privateEvent: (0, _computed.equal)("model.eventModel.status", "private"),
    onChangeDates(changes) {
      this.model.eventModel.setProperties({
        starts_at: changes.from,
        ends_at: changes.to
      });
    },
    onChangeTimezone(newTz) {
      this.model.eventModel.setProperties({
        timezone: newTz,
        starts_at: replaceTimezone(this.startsAt, newTz),
        ends_at: this.endsAt && replaceTimezone(this.endsAt, newTz)
      });
    },
    destroyPostEvent() {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_post_event.builder_modal.confirm_delete"),
        didConfirm: () => {
          return this.store.find("post", this.model.eventModel.id).then(post => {
            const raw = post.raw;
            const newRaw = this._removeRawEvent(raw);
            const props = {
              raw: newRaw,
              edit_reason: _I18n.default.t("discourse_post_event.destroy_event")
            };
            return (0, _text.cookAsync)(newRaw).then(cooked => {
              props.cooked = cooked.string;
              return post.save(props).catch(e => this.flash((0, _ajaxError.extractError)(e), "error")).then(result => result && this.send("closeModal"));
            });
          }).catch(e => this.flash((0, _ajaxError.extractError)(e), "error"));
        }
      });
    },
    createEvent() {
      if (!this.startsAt) {
        this.send("closeModal");
        return;
      }
      const eventParams = (0, _rawEventHelper.buildParams)(this.startsAt, this.endsAt, this.model.eventModel, this.siteSettings);
      const markdownParams = [];
      Object.keys(eventParams).forEach(key => {
        let value = eventParams[key];
        markdownParams.push(`${key}="${value}"`);
      });
      this.toolbarEvent.addText(`[event ${markdownParams.join(" ")}]\n[/event]`);
      this.send("closeModal");
    },
    updateEvent() {
      return this.store.find("post", this.model.eventModel.id).then(post => {
        const raw = post.raw;
        const eventParams = (0, _rawEventHelper.buildParams)(this.startsAt, this.endsAt, this.model.eventModel, this.siteSettings);
        const newRaw = (0, _rawEventHelper.replaceRaw)(eventParams, raw);
        if (newRaw) {
          const props = {
            raw: newRaw,
            edit_reason: _I18n.default.t("discourse_post_event.edit_reason")
          };
          return (0, _text.cookAsync)(newRaw).then(cooked => {
            props.cooked = cooked.string;
            return post.save(props).catch(e => this.flash((0, _ajaxError.extractError)(e), "error")).then(result => result && this.send("closeModal"));
          });
        }
      });
    },
    _removeRawEvent(raw) {
      const eventRegex = new RegExp(`\\[event\\s(.*?)\\]\\n\\[\\/event\\]`, "m");
      return raw.replace(eventRegex, "");
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeCustomField", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeCustomField"), _obj), _applyDecoratedDescriptor(_obj, "onChangeStatus", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeStatus"), _obj), _applyDecoratedDescriptor(_obj, "setRawInvitees", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setRawInvitees"), _obj), _applyDecoratedDescriptor(_obj, "removeReminder", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeReminder"), _obj), _applyDecoratedDescriptor(_obj, "addReminder", [_object.action], Object.getOwnPropertyDescriptor(_obj, "addReminder"), _obj), _applyDecoratedDescriptor(_obj, "onChangeDates", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeDates"), _obj), _applyDecoratedDescriptor(_obj, "onChangeTimezone", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChangeTimezone"), _obj), _applyDecoratedDescriptor(_obj, "destroyPostEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyPostEvent"), _obj), _applyDecoratedDescriptor(_obj, "createEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "createEvent"), _obj), _applyDecoratedDescriptor(_obj, "updateEvent", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateEvent"), _obj)), _obj));
});