define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event", ["exports", "@ember/object", "@ember/string", "discourse/helpers/route-action", "discourse/lib/export-csv", "discourse/lib/show-modal", "discourse/lib/text", "discourse/lib/utilities", "discourse/widgets/widget", "I18n", "discourse/plugins/discourse-calendar/discourse/lib/clean-title", "discourse/plugins/discourse-calendar/discourse/lib/raw-event-helper"], function (_exports, _object, _string, _routeAction, _exportCsv, _showModal, _text, _utilities, _widget, _I18n, _cleanTitle, _rawEventHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event", {
    tagName: "div.discourse-post-event-widget",
    services: ["dialog"],
    buildKey: attrs => `discourse-post-event-${attrs.id}`,
    buildClasses() {
      if (this.state.event) {
        return ["has-discourse-post-event"];
      }
    },
    inviteUserOrGroup(postId) {
      this.store.find("discourse-post-event-event", postId).then(eventModel => {
        (0, _showModal.default)("discourse-post-event-invite-user-or-group", {
          model: eventModel
        });
      });
    },
    showAllInvitees(params) {
      const postId = params.postId;
      const title = params.title || "title_invited";
      const extraClass = params.extraClass || "invited";
      const name = "discourse-post-event-invitees";
      this.store.find("discourse-post-event-event", postId).then(eventModel => {
        (0, _showModal.default)(name, {
          model: eventModel,
          title: `discourse_post_event.invitees_modal.${title}`,
          modalClass: [`${(0, _string.dasherize)(name).toLowerCase()}-modal`, extraClass].join(" ")
        });
      });
    },
    editPostEvent(postId) {
      this.store.find("discourse-post-event-event", postId).then(eventModel => {
        (0, _showModal.default)("discourse-post-event-builder", {
          model: {
            eventModel,
            topicId: eventModel.post.topic.id
          }
        });
      });
    },
    closeEvent(eventModel) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_post_event.builder_modal.confirm_close"),
        didConfirm: () => {
          return this.store.find("post", eventModel.id).then(post => {
            const raw = post.raw;
            const startsAt = eventModel.starts_at ? moment(eventModel.starts_at) : moment();
            const eventParams = (0, _rawEventHelper.buildParams)(moment().isBefore(startsAt) ? moment() : startsAt, moment().isBefore(startsAt) ? moment().add(1, "minute") : moment(), eventModel, this.siteSettings);
            const newRaw = (0, _rawEventHelper.replaceRaw)(eventParams, raw);
            if (newRaw) {
              const props = {
                raw: newRaw,
                edit_reason: _I18n.default.t("discourse_post_event.edit_reason")
              };
              return (0, _text.cookAsync)(newRaw).then(cooked => {
                props.cooked = cooked.string;
                return post.save(props);
              });
            }
          });
        }
      });
    },
    changeWatchingInviteeStatus(status) {
      if (this.state.eventModel.watching_invitee) {
        const currentStatus = this.state.eventModel.watching_invitee.status;
        let newStatus = status;
        if (status === currentStatus && status === "interested") {
          newStatus = null;
        }
        this.store.update("discourse-post-event-invitee", this.state.eventModel.watching_invitee.id, {
          status: newStatus,
          post_id: this.state.eventModel.id
        });
      } else {
        this.store.createRecord("discourse-post-event-invitee").save({
          post_id: this.state.eventModel.id,
          status
        });
      }
    },
    defaultState(attrs) {
      return {
        eventModel: attrs.eventModel
      };
    },
    exportPostEvent(postId) {
      (0, _exportCsv.exportEntity)("post_event", {
        name: "post_event",
        id: postId
      });
    },
    bulkInvite(eventModel) {
      (0, _showModal.default)("discourse-post-event-bulk-invite", {
        model: {
          eventModel
        }
      });
    },
    sendPMToCreator() {
      const router = this.register.lookup("service:router")._router;
      (0, _routeAction.routeAction)("composePrivateMessage", router, _object.default.create(this.state.eventModel.creator), _object.default.create(this.state.eventModel.post)).call();
    },
    addToCalendar() {
      const event = this.state.eventModel;
      this.attrs.api.downloadCalendar(event.name || event.post.topic.title, [{
        startsAt: event.starts_at,
        endsAt: event.ends_at
      }]);
    },
    leaveEvent(postId) {
      this.store.findAll("discourse-post-event-invitee", {
        post_id: postId
      }).then(invitees => {
        invitees.find(invitee => invitee.id === this.state.eventModel.watching_invitee.id).destroyRecord();
      });
    },
    transform() {
      const eventModel = this.state.eventModel;
      return {
        eventStatusLabel: _I18n.default.t(`discourse_post_event.models.event.status.${eventModel.status}.title`),
        eventStatusDescription: _I18n.default.t(`discourse_post_event.models.event.status.${eventModel.status}.description`),
        startsAtMonth: moment(eventModel.starts_at).format("MMM"),
        startsAtDay: moment(eventModel.starts_at).format("D"),
        eventName: (0, _text.emojiUnescape)((0, _utilities.escapeExpression)(eventModel.name) || this._cleanTopicTitle(eventModel.post.topic.title, eventModel.starts_at)),
        statusClass: `status ${eventModel.status}`,
        isPublicEvent: eventModel.status === "public",
        isStandaloneEvent: eventModel.status === "standalone",
        canActOnEvent: this.currentUser && this.state.eventModel.can_act_on_discourse_post_event
      };
    },
    template: function (attrs, state) {
      var __h1 = __widget_helpers.rawHtml;
      var _r = [];
      _r.push("\n");
      if (state.eventModel) {
        _r.push("      ");
        var _a0 = [];
        _a0.push("\n        ");
        var _a1 = [];
        _a1.push("\n          ");
        var _a2 = [];
        _a2.push(this.transformed.startsAtMonth);
        _a1.push(virtualDom.h('div', {
          "className": "month",
          "attributes": {}
        }, _a2));
        _a1.push("\n          ");
        var _a3 = [];
        _a3.push(this.transformed.startsAtDay);
        _a1.push(virtualDom.h('div', {
          "className": "day",
          "attributes": {}
        }, _a3));
        _a1.push("\n        ");
        _a0.push(virtualDom.h('div', {
          "className": "event-date",
          "attributes": {}
        }, _a1));
        _a0.push("\n        ");
        var _a4 = [];
        _a4.push("\n          ");
        var _a5 = [];
        _a5.push("\n            ");
        _a5.push(new __h1({
          html: '<span>' + this.transformed.eventName + '</span>'
        }));
        _a5.push("\n          ");
        _a4.push(virtualDom.h('span', {
          "className": "name",
          "attributes": {}
        }, _a5));
        _a4.push("\n          ");
        var _a6 = [];
        _a6.push("\n");
        if (!this.transformed.isStandaloneEvent) {
          if (state.eventModel.is_expired) {
            _a6.push("                ");
            var _a7 = [];
            _a7.push("\n                  ");
            _a7.push(_I18n.default.t("discourse_post_event.models.event.expired"));
            _a7.push("\n                ");
            _a6.push(virtualDom.h('span', {
              "className": "status expired",
              "attributes": {}
            }, _a7));
            _a6.push("\n");
          } else {
            _a6.push("                ");
            var _a8 = [];
            _a8.push("\n                  ");
            _a8.push(this.transformed.eventStatusLabel);
            _a8.push("\n                ");
            _a6.push(virtualDom.h('span', {
              "className": this.transformed.statusClass,
              "attributes": {
                "title": this.transformed.eventStatusDescription
              }
            }, _a8));
            _a6.push("\n");
          }
          _a6.push("              ");
          var _a9 = [];
          _a9.push("·");
          _a6.push(virtualDom.h('span', {
            "className": "separator",
            "attributes": {}
          }, _a9));
          _a6.push("\n");
        }
        _a6.push("            ");
        var _a10 = [];
        _a10.push("\n              ");
        var _a11 = [];
        _a11.push(_I18n.default.t("discourse_post_event.event_ui.created_by"));
        _a10.push(virtualDom.h('span', {
          "className": "created-by",
          "attributes": {}
        }, _a11));
        _a10.push("\n              ");
        _a10.push(this.attach("discourse-post-event-creator", {
          "user": state.eventModel.creator
        }, undefined, undefined));
        _a10.push("\n            ");
        _a6.push(virtualDom.h('span', {
          "className": "creators",
          "attributes": {}
        }, _a10));
        _a6.push("\n          ");
        _a4.push(virtualDom.h('div', {
          "className": "status-and-creators",
          "attributes": {}
        }, _a6));
        _a4.push("\n        ");
        _a0.push(virtualDom.h('div', {
          "className": "event-info",
          "attributes": {}
        }, _a4));
        _a0.push("\n\n        ");
        _a0.push(this.attach("more-dropdown", {
          "canActOnEvent": this.transformed.canActOnEvent,
          "isPublicEvent": this.transformed.isPublicEvent,
          "eventModel": state.eventModel
        }, undefined, undefined));
        _a0.push("\n      ");
        _r.push(virtualDom.h('header', {
          "className": "event-header",
          "attributes": {}
        }, _a0));
        _r.push("\n\n");
        if (state.eventModel.can_update_attendance) {
          _r.push("        ");
          var _a12 = [];
          _a12.push("\n          ");
          _a12.push(this.attach("discourse-post-event-status", {
            "watchingInvitee": this.state.eventModel.watching_invitee,
            "minimal": this.state.eventModel.minimal
          }, undefined, undefined));
          _a12.push("\n        ");
          _r.push(virtualDom.h('section', {
            "className": "event-actions",
            "attributes": {}
          }, _a12));
          _r.push("\n");
        }
        _r.push("\n");
        if (this.state.eventModel.url) {
          _r.push("        ");
          var _a13 = [];
          _r.push(virtualDom.h('hr', _a13));
          _r.push("\n\n        ");
          _r.push(this.attach("discourse-post-event-url", {
            "url": this.state.eventModel.url
          }, undefined, undefined));
          _r.push("\n");
        }
        _r.push("\n      ");
        var _a14 = [];
        _r.push(virtualDom.h('hr', _a14));
        _r.push("\n\n      ");
        _r.push(this.attach("discourse-post-event-dates", {
          "localDates": attrs.localDates,
          "eventModel": state.eventModel
        }, undefined, undefined));
        _r.push("\n\n");
        if (!state.eventModel.minimal) {
          if (state.eventModel.should_display_invitees) {
            _r.push("          ");
            var _a15 = [];
            _r.push(virtualDom.h('hr', _a15));
            _r.push("\n\n          ");
            _r.push(this.attach("discourse-post-event-invitees", {
              "eventModel": state.eventModel
            }, undefined, undefined));
            _r.push("\n");
          }
        }
      }
      _r.push("  ");
      return _r;
    },
    _cleanTopicTitle(topicTitle, startsAt) {
      topicTitle = (0, _utilities.escapeExpression)(topicTitle);
      const cleaned = (0, _cleanTitle.default)(topicTitle, startsAt);
      if (cleaned) {
        return topicTitle.replace(cleaned, "");
      }
      return topicTitle;
    }
  });
});